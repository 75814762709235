import { useState } from "react";
import { ErrorToast, SuccessToast } from "../loader/Toast";
import { useDispatch } from "react-redux";
import { login } from "../store/authAction";

const Login = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const AuthLogin = async () => {
    if (!username) {
      ErrorToast("Email is required");
      return false;
    }

    if (!password) {
      ErrorToast("Password is required");
      return false;
    }

    setIsLoader(true);
    try {
      const response = await fetch(
        "https://ardhsainikcanteen.in/api/franchise-login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username,
            password: password,
          }),
        }
      );

      if (!response.ok) {
        const result = await response.json();
        ErrorToast(result.msg);
      }

      const result = await response.json();

      if (result.status) {
        setIsLoader(false);
        const token = result.id;
        await localStorage.setItem("TOKEN", JSON.stringify({ token }));
        dispatch(login());
        SuccessToast(result.msg);
      } else {
        setIsLoader(false);
        ErrorToast(result.msg);
      }
    } catch (e) {
      console.error("Error sending data:", e);
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img
            className="w-30 h-40 mr-2"
            src="https://www.ardhsainikcanteen.in/public/assets/website/images/asc_logo.jpg"
            alt="logo"
          />
        </a>
        <div className="w-full bg-white rounded-lg shadow-xl dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Your Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter your email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              onClick={AuthLogin}
              className={`bg-orange-500 w-full text-white hover:bg-orange-600 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800 gap-2 ${
                isLoader ? "disabled:opacity-25" : ""
              }`}
              disabled={isLoader}
            >
              {isLoader ? <i className={"fa fa-spinner fa-spin"}></i> : ""} Sign
              in
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
