import React from "react";
import TabHome from "./tab/TabHome";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import MobileHome from "./mobile/MobileHome";
import TabHoldOrders from "./tab/TabHoldOrders";
import TabTransactions from "./tab/TabTransactions";
import Scanner from "./BarcodeScanner/Scanner";
import MobileHoldOrders from "./mobile/MobileHoldOrders";
import MobileTransactions from "./mobile/MobileTransactions";
import TabAbhhyamTransactions from "./tab/TabAbhhyamTransactions";
import CanteenInvoice from "./tab/CanteenInvoice";
import AbhhyamInvoicePage from "./tab/AbhhyamInvoicePage";
import MobileAbhhyamTransactions from "./mobile/MobileAbhhyamTransactions";
import Login from "./login/Login";
import { legacy_createStore as createStore } from "redux";
import authReducer from "./store/authReducer";
import { Provider } from "react-redux";

function App() {
  const store = createStore(authReducer);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path={"/login"} element={<Login />} />
          <Route
            path={":canteenId?/:barcode?"}
            element={
              isTablet ? <TabHome /> : isMobile ? <MobileHome /> : <TabHome />
            }
          />
          <Route
            path={"/hold-orders/:canteenId"}
            element={
              isTablet ? (
                <TabHoldOrders />
              ) : isMobile ? (
                <MobileHoldOrders />
              ) : (
                <TabHoldOrders />
              )
            }
          />
          <Route
            path={"/transactions/:canteenId"}
            element={
              isTablet ? (
                <TabTransactions />
              ) : isMobile ? (
                <MobileTransactions />
              ) : (
                <TabTransactions />
              )
            }
          />
          <Route
            path={"/abhhyamTransactions/:canteenId"}
            element={
              isTablet ? (
                <TabAbhhyamTransactions />
              ) : isMobile ? (
                <MobileAbhhyamTransactions />
              ) : (
                <TabAbhhyamTransactions />
              )
            }
          />
          <Route
            path={"/canteenInvoice/:canteenId/:phoneNumber/:id"}
            element={<CanteenInvoice />}
          />
          <Route
            path={"/abhhyamInvoice/:canteenId/:phoneNumber/:id"}
            element={<AbhhyamInvoicePage />}
          />
          <Route path={"/scanner/:canteenId"} element={<Scanner />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
